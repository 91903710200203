<template>
  <div class="TrustServices">
    <div class="banner">
      <img width="100%" src="../assets/banner7.png" alt="" />
      <div class="title">
        <div class="h1">TRUST SERVICE</div>
      </div>
      <!-- <div class="min_background"></div> -->
    </div>
    <div class="navpc">
      <div class="Headline">
        <div
          v-for="item in title"
          :key="item.index"
          @click="handleTiele(item.index)"
          :class="{ active: item.index === index }"
          v-html="item.title"
        ></div>
      </div>
      <div class="nav">
        <!-- <img class="before" width="100%" src="../assets/矩形 2.png" alt="" /> -->
        <img width="100%" :src="imgs[index].url" alt="" />
        <!-- <img width="100%" src="../assets/TrustServices1.jpg" alt="" />
        <img width="100%" src="../assets/TrustServices2.jpg" alt="" />
        <img width="100%" src="../assets/TrustServices3.jpg" alt="" />
        <img width="100%" src="../assets/TrustServices4.jpg" alt="" />
        <img width="100%" src="../assets/TrustServices5.jpg" alt="" /> -->
        <div class="container">
          <div class="text">
            <div class="text_1">
              <p>
                {{ imgs[index].text1 }}
              </p>
              <p style="margin: 0px">{{ imgs[index].text1_2 }}</p>
            </div>
            <div>
              <p v-html="imgs[index].text2"></p>
              <p style="margin: 0px" v-html="imgs[index].text2_2"></p>
              <p style="margin: 0px">{{ imgs[index].text2_3 }}</p>
            </div>
            <div class="text_2">
              <div class="left">
                <p>{{ imgs[index].left_1 }}</p>
                <p>{{ imgs[index].left_2 }}</p>
                <p>{{ imgs[index].left_3 }}</p>
                <p>{{ imgs[index].left_4 }}</p>
                <p>{{ imgs[index].left_5 }}</p>
              </div>
              <div class="right">
                <p>{{ imgs[index].right_1 }}</p>
                <p>{{ imgs[index].right_2 }}</p>
                <p>{{ imgs[index].right_3 }}</p>
                <p>{{ imgs[index].right_4 }}</p>
                <p>{{ imgs[index].right_5 }}</p>
              </div>
            </div>
            <div>
              <p v-html="imgs[index].text3"></p>
              <p style="margin: 0px" v-html="imgs[index].text3_2"></p>
              <p style="margin: 0px">{{ imgs[index].text3_3 }}</p>
            </div>
            <div>
              <p v-html="imgs[index].text4"></p>
              <p style="margin: 0px" v-html="imgs[index].text4_2"></p>
              <p style="margin: 0px">{{ imgs[index].text4_3 }}</p>
            </div>
            <div>
              <p>{{ imgs[index].text5 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navmin">
      <div class="m_title">{{ imgs[m_index].title }}</div>
      <div class="nav">
        <div class="container">
          <van-swipe @change="onChange">
            <van-swipe-item v-for="(item, index) in imgs" :key="index">
              <img width="100%" :src="item.url" alt="" />
              <div class="text">
                <div class="text_1">
                  <p v-html="item.text1"></p>
                  <p>{{ item.text1_2 }}</p>
                </div>
                <div>
                  <p style="margin-bottom: 5px" v-html="item.text2"></p>
                  <p style="font-size: 12px" v-html="item.text2_2"></p>
                  <p>{{ item.text2_3 }}</p>
                </div>
                <div>
                  <p
                    style="margin-bottom: 5px; margin-top: 5px"
                    v-html="item.text3"
                  ></p>
                  <p style="font-size: 12px" v-html="item.text3_2"></p>
                  <p>{{ item.text3_3 }}</p>
                </div>
                <div>
                  <p
                    style="margin-bottom: 5px"
                    v-html="item.text4"
                    class="lineheight"
                  ></p>
                  <p style="font-size: 12px" v-html="item.text4_2"></p>
                  <p>{{ item.text4_3 }}</p>
                </div>
                <div>
                  <p style="margin: 10px auto">{{ item.left_1 }}</p>
                  <p>{{ item.left_2 }}</p>
                  <p>{{ item.left_3 }}</p>
                  <p>{{ item.left_4 }}</p>
                  <p>{{ item.left_5 }}</p>
                </div>
                <div>
                  <p style="margin: 10px auto">{{ item.right_1 }}</p>
                  <p>{{ item.right_2 }}</p>
                  <p>{{ item.right_3 }}</p>
                  <p>{{ item.right_4 }}</p>
                  <p>{{ item.right_5 }}</p>
                </div>
                <div>
                  <p>{{ item.text5 }}</p>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
          <!-- <div class="text">
            <img width="100%" src="../assets/TrustServices1.png" alt="" />
            <div class="navminTitle" v-html="imgs[0].title"></div>
            <div class="text_1">
              <p>
                {{ imgs[0].text1 }}
              </p>
            </div>
            <div>
              <p>{{ imgs[0].text2 }}</p>
            </div>
          </div>
          <div class="text">
            <img width="100%" src="../assets/TrustServices2.png" alt="" />
            <div class="navminTitle" v-html="imgs[1].title"></div>
            <div class="text_1">
              <p>
                {{ imgs[1].text1 }}
              </p>
            </div>
            <div>
              <p>{{ imgs[1].text2 }}</p>
            </div>
          </div>
          <div class="text">
            <img width="100%" src="../assets/TrustServices3min.jpg" alt="" />
            <div class="navminTitle">{{ imgs[2].title }}</div>
            <div class="text_1">
              <p>
                {{ imgs[2].text1 }}
              </p>
              <p>{{ imgs[2].text1_2 }}</p>
            </div>
            <div>
              <p v-html="imgs[2].text2" class="lineheight"></p>
              <p v-html="imgs[2].text2_2"></p>
              <p>{{ imgs[2].text2_3 }}</p>
            </div>
            <div>
              <p v-html="imgs[2].text3" class="lineheight"></p>
              <p v-html="imgs[2].text3_2"></p>
              <p>{{ imgs[2].text3_3 }}</p>
            </div>
            <div>
              <p v-html="imgs[2].text4" class="lineheight"></p>
              <p v-html="imgs[2].text4_2"></p>
              <p>{{ imgs[2].text4_3 }}</p>
            </div>
          </div>
          <div class="text">
            <img width="100%" src="../assets/TrustServices4min.jpg" alt="" />
            <div class="navminTitle">{{ imgs[3].title }}</div>
            <div class="text_1">
              <p>
                {{ imgs[3].text1 }}
              </p>
            </div>
            <div>
              <p>{{ imgs[3].text2 }}</p>
            </div>
            <div>
              <p style="margin: 10px auto">{{ imgs[3].left_1 }}</p>
              <p>{{ imgs[3].left_2 }}</p>
              <p>{{ imgs[3].left_3 }}</p>
              <p>{{ imgs[3].left_4 }}</p>
              <p>{{ imgs[3].left_5 }}</p>
            </div>
            <div>
              <p style="margin: 10px auto">{{ imgs[3].right_1 }}</p>
              <p>{{ imgs[3].right_2 }}</p>
              <p>{{ imgs[3].right_3 }}</p>
              <p>{{ imgs[3].right_4 }}</p>
              <p>{{ imgs[3].right_5 }}</p>
            </div>
          </div>
          <div class="text">
            <img width="100%" src="../assets/TrustServices5.png" alt="" />
            <div class="navminTitle">{{ imgs[4].title }}</div>
            <div class="text_1">
              <p>
                {{ imgs[4].text1 }}
              </p>
            </div>
            <div>
              <p>{{ imgs[4].text2 }}</p>
            </div>
            <div>
              <p>{{ imgs[4].text3 }}</p>
            </div>
            <div>
              <p>{{ imgs[4].text4 }}</p>
            </div>
            <div>
              <p>{{ imgs[4].text5 }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      index: 0,
      m_index: 0,
      title: [
        {
          title: "<div>History <br>of Trust</div>",
          index: 0,
        },
        {
          title: "<div>Demand <br>of Trust</div>",
          index: 1,
        },
        {
          title: "<div>Structure <br>of Trust</div>",
          index: 2,
        },
        {
          title: "<div>Operation <br>of Trust</div>",
          index: 3,
        },
        {
          title: "Trust Deed",
          index: 4,
        },
      ],
      imgs: [
        {
          title: "History of Trust",
          url: "https://www.hongkongtrust.com.hk/vue/img/TrustServices1.jpg",
          text1:
            "Trust service is a wealth management service invented in western countries centuries ago. The service has been adopted extensively by the rich and the famous. For example, Family trusts of Rockefeller and McDonald’s, Charity trusts of Bill Gates, Warren Buffet of USA and Li Ka Shing of Hong Kong as well as the world-renown Nobel Prize, etc.",
          text2:
            "Hong Kong Trust Law is based on the British Trust Act of the 19th Century. The Trust Law (Amendment) Bill 2013 was passed and came into effect on 1st December 2013. This is the first major amendment to Hong Kong’s Trustee Ordinance since its enactment in 1934, and the Perpetuities and Accumulations Ordinance enactment in 1970. Trust in different countries has expiry (e.g. 70 years, etc) but such expiry has been abolished in the trust law amendment of Hong Kong, which demonstrates Hong Kong’s determination to be a leading trust service region in the world by offering perpetual trust service to customers who will be benefited from it.",
        },
        {
          title: "Demand of Trust",
          url: "https://www.hongkongtrust.com.hk/vue/img/TrustServices2.jpg",
          text1:
            "Hong Kong is a world-renown financial centre. Trust service is an integral part of financial service and trust is widely recognized as an effective financial instrument for wealth management. We are Trust professionals and we understand your needs. Your wealth needs safeguarding, growth and smooth inheritance and Trust is just for you. Over the years, we have arranged various trust service to meet the needs of our customers who are satisfied with our service.",
          text2:
            "Like our existing customers, you have made wealth out of your effort and wisdom, we venture to say that you are in need of Trust to manage your wealth as well. You want to preserve your wealth so that it will not suffer from loss or damage at times of adversity. You want your wealth to grow because you deserve a bigger harvest out of your hardworking. You want a smooth inheritance of your wealth as you want to provide a better life to your family. We understand your needs and we are ready to achieve your goal!",
        },
        {
          title: "Structure of Trust",
          url: "https://www.hongkongtrust.com.hk/vue/img/TrustServices3.jpg",
          text1:
            "Trust exists in the form of trust deed which is the result of thorough consideration. Once a trust deed becomes effective, it must be executed faithfully as it is the very essence of trust relationship.",
          text1_2:
            "The Three Parties involved in Trust——The settlor, The trustee, The beneficiary.",
          text2:
            "<span style=font-weight:500;letter-spacing:2px;>◆ The settlors</span>",
          text2_2:
            "<span style=font-weight:500;letter-spacing:2px;>An individual who transfers assets to a trustee.</span>",
          text2_3:
            "The Settlor is the person who sets up a trust. He should be a natural person, legal person or other organization established by law with full civil capacity. He is the owner of the wealth to be put in a trust, capable to designate the beneficiary of the wealth, appoint trustee and monitor its performance in execution of the trust deed.",
          text3:
            "<span style=font-weight:500;letter-spacing:2px;>◆ The trustee</span>",
          text3_2:
            "<span style=font-weight:500;letter-spacing:2px;>The wealth management body who will manage the asset in trust for the settlor.</span>",
          text3_3:
            "The trustee is in charge of the duty to manage, distribute or allocate the wealth under its management. The trustee should be a natural person, legal person established by law with full civil capacity. The trustee should perform their duties with honesty, integrity, prudence and effectiveness in operation. The trustee should serve for the maximum benefit for  the settlor and manage the assets in the trust according to the trust deed.",
          text4:
            "<span style=font-weight:500;letter-spacing:2px;>◆ The beneficiary</span>",
          text4_2:
            "<span style=font-weight:500;letter-spacing:2px;>The trustee allocates the assets in trust to the beneficiary according to relevant trust deed.</span>",
          text4_3:
            "The beneficiary is entitled to the benefits of the wealth managed by a trustee. He should be a natural person, legal person or other organization established by law with full civil capacity. The beneficiary of charity trust should be the people in the public.",
        },
        {
          title: "Operation of Trust",
          url: "https://www.hongkongtrust.com.hk/vue/img/TrustServices4.jpg",
          /* text1:
            "When you enter into a trust deed and transfer the ownership of your asset to the trustee, the ownership and management of your asset is separated. Although you do not own the asset under the trust, you can still manage your asset via the trust deed and you, making yourself the beneficiary of the asset in the trust plan, are entitled to the profit generated from it, just the same before you put the asset in the trust plan. The lawyer who draws up the Trust Deed can confirm such mechanism.", */
          text2:
            "Operation of the trust refers to wealth management according to the terms and conditions set by you in the trust deed.",
          left_1: "Actual operation of trust plan includes:",
          left_2: "◆Investment.",
          left_3: "◆Distribution of the asset.",
          left_4: "◆Disposal of the profit generated from the asset.",
          left_5: "◆Settlement of payments arisen from the asset.",
          right_1: "For example, you can stipulate in the trust deed that:",
          right_2:
            "◆Investment in fixed deposit or bond to safeguard your wealth.",
          right_3:
            "◆Investment in real estate, equity shares (listed or private) for capital growth.",
          right_4:
            "◆Transfer part of or all of your assets or the profit generated from your trust to the beneficiaries when they fulfill certain pre-requisites, giving them financial independence.",
          right_5:
            "You, as the de-facto owner of the wealth, can set many different pre-requisites in the trust deed.",
        },
        {
          title: "Trust deed",
          url: "https://www.hongkongtrust.com.hk/vue/img/TrustServices5.jpg",
          text1:
            "When you enter into a trust deed and transfer the ownership of your asset to the trustee, the ownership and management of your asset are separated. Although you do not own the asset under the trust, you can still manage your asset via the trust deed, making yourself the beneficiary of the asset in the trust. The lawyer who draws up the Trust Deed can confirm such mechanism.",
          /*  text2:
            "A Trustee (i.e. a trust company) is a professional legal entity that provides you with the trust plan for wealth management. The trustee is a professional legal entity because it has to obtain trustee license from the government and is subject to supervision by regulatory authority so that it is qualified to provide a trust plan and manage your wealth.",
          text3:
            "For wealth management, you have to transfer your wealth into a trust plan provided by your trustee, which means the trustee will become the owner of your wealth and you will no longer own the asset under the trustee by law.",
          text4:
            "However, a Trust Plan is a fixed mechanism, which is the terms and conditions set by you to govern or regulate the operation of the Trust Plan. Trust Deed is a contract containing the terms and conditions that governs the operation of the Trust Plan. It is an agreement between you and your trustee who will operate the Trust Plan according to the terms and conditions set by you. So, your Trustee is actually the nominal owner of your wealth and the administrator for your wealth management. As you will command the wealth management via the trust deed, you are the de facto owner of your wealth in the trust plan. As you can set the mechanism of trust operation, you certainly can make yourself the beneficiary of the asset in the trust plan according to the trust law. This is a manifestation that you still have the ownership of the asset in the trust plan.",
          text5:
            "In summary, you will still own the asset under trust and will be entitled to the interest, profit generated from it by setting the mechanism of trust operation and making yourself the beneficiary of the asset in the trust plan.", */
        },
      ],
    };
  },
  methods: {
    handleTiele(data) {
      this.index = data;
    },
    onChange(index) {
      this.m_index = index;
      console.log("当前 Swipe 索引：" + index);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100vw;
  /* background: url("../assets/banner7.png") no-repeat; */
  position: relative;
  .min_background {
    display: none;
    background: #fff;
    height: 17px;
    position: absolute;
    width: 84vw;
    margin-left: 8vw;
    bottom: 0;
  }
}
.title {
  color: #fff;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  /* z-index: 100; */
  position: absolute;
  top: 45%;
  font-size: 55px;
}
.Headline {
  width: 60vw;
  display: flex;
  margin: 17px auto 0;
  justify-content: space-evenly;
  color: #bebebe;
  font-family: Georgia;
  font-size: 26px;
  div {
    width: 16%;
    font-weight: 500;
  }
}
.Headline div:hover {
  color: #a95228;
}
.navmin {
  display: none;
}
.nav {
  margin-top: 17px;
  position: relative;
  margin-bottom: -67px;
  .before {
    position: absolute;
  }
  .container {
    position: absolute;
    top: 50%;
    left: 20vw;
    width: 60vw;
    transform: translate(0%, -50%);
    .text {
      text-align: justify;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      padding-left: 20px;
      font-family: "contert";
      line-height: 25px;
      .text_1 {
        margin-top: 30px;
        margin-bottom: 25px;
        border-top: 1px solid #fff;
        padding-top: 1vw;
      }
      .text_2 {
        display: flex;
        margin-top: 1vw;
      }
      p {
        margin-bottom: 0px;
      }
      div {
        .left {
          width: 50%;
          p {
            width: 80%;
            margin: 0px;
          }
        }
        .right {
          width: 50%;
          p {
            margin: 0px;
          }
        }
      }
    }
  }
  .headline {
    display: flex;
    color: #fff;
    font-size: 24px;
    line-height: 48px;
    font-weight: 500;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    padding: 0px 20px;
  }
}
.active {
  font-weight: 500;
  color: #000;
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .Headline {
    width: 80vw;
    font-size: 20px;
  }
  .nav .container {
    .text {
      font-size: 16px;
    }
    width: 80vw;
    left: 10vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title {
    font-size: 36px;
  }
  .Headline {
    width: 80vw;
    font-size: 16px;
  }
  .nav .container {
    .text {
      font-size: 10px;
      p {
        margin-bottom: -2px;
      }
      .text_1 {
        margin-bottom: 10px;
        margin-top: 20px;
        padding-top: 0px;
      }
      .text_2 {
        margin-top: 0px;
        div {
          padding-top: 5px;
        }
      }
    }
    width: 90vw;
    left: 5vw;
  }
}
@media (max-width: 767px) {
  // // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  ::v-deep .van-swipe__indicator {
    background-color: #371010;
  }
  ::v-deep .van-swipe__indicator--active {
    background-color: #1989fa;
  }
  .title {
    font-size: 30px;
    top: 35%;
  }
  .banner {
    margin-top: 64px;
    .min_background {
      display: block;
    }
  }
  .nav {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .navpc {
    display: none;
  }
  .navmin {
    display: block;
    width: 84vw;
    margin: 0 auto;
    .m_title {
      font-size: 20px;
      color: #5d5d5d;
    }
    .container {
      width: 100%;
      background-size: cover;
      position: relative;
      left: 0px;
      transform: translate(0%, 0%);
      .text {
        /* background-image: url("../assets/TrustServices1.jpg");
        background-size: cover; */
        color: #000;
        /* width: 90vw; */
        margin: 0 auto;
        font-size: 14px;
        padding: 5px;
        margin-bottom: 10px;
        background-color: #fff;
        padding: 0px;
        padding-bottom: 10px;
        text-align: left;

        /* padding-left: 5vw;
        padding-right: 5vw; */
        /* border-bottom: 1px solid; */
        p {
          width: 94%;
          margin: 0 auto;
          margin-top: 0px;
        }
        .navminTitle {
          width: 96%;
          margin: 0 auto;
          font-size: 16px;
          font-weight: 500;
        }
        .text_1 {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
.lineheight {
  line-height: 2;
}
</style>
